import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { auth } from 'firebase/app'
import { AngularFireAuth } from '@angular/fire/auth'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore'
import { AngularFireFunctions } from '@angular/fire/functions'

import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/of'
import { switchMap, startWith, tap } from 'rxjs/operators'

import { userRoles } from '../variables'
import { NotifyService } from '../notify/notify.service'
import { User } from '../../models'
import { environment } from '../../../environments/environment.prod'

@Injectable()
export class AuthService {

  user: Observable<User>

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private router: Router,
    private notify: NotifyService,
  ) {
    console.log('authservice inited')
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          //set status as active
          this.afs.doc(`users/${user.uid}`).update({
            status: "Active" });
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
          return Observable.of(null)
        }
      }),
      tap(user => {
        //load role
        let checkOrg = localStorage.getItem('group-org');
        let checkRole = localStorage.getItem('current-role');
        localStorage.setItem('user', JSON.stringify(user));
        if(user == null) {return null}
        //update role if not exist
        if(checkOrg == undefined || checkOrg == null) {
          checkOrg = user.orgId;
          localStorage.setItem('current-role', user.role);
          localStorage.setItem('group-org', user.orgId);
        }

        //check for role update in primamry org
        if(checkOrg == user.orgId && checkRole != user.role) {
          localStorage.setItem('current-role', user.role);
        }

        //role validation
        if(user.role != userRoles.SysAdmin && checkOrg != user.orgId) {
          this.checkForUserRole(user.uid, localStorage.getItem('group-org'))
          .subscribe(orgMember => {
            console.log(orgMember)
            orgMember.length == 0 ? this.signOut() : localStorage.setItem('current-role', orgMember[0].role);
          });
        }
      }),
      // startWith(JSON.parse(localStorage.getItem('user')))
    )
  }

  // If error, console log and notify user
  private handleError(error) {
    console.error(error)
    this.notify.update(error.message, 'warning')
  }

  // Sets user data to firestore after successful registration
  private setUserDoc(user: User, data?: User): Promise<void> {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`)
    const userDoc: User = {
      uid: user.uid,
      email: user.email || null
    }
    if (data) {
      Object.assign(userDoc, data)
    }
    return userRef.set(userDoc)
  }

  //// Email/Password Auth ////
  // Email/Password Register
  emailSignUp(email: string, password: string, data = null) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(user => {
        // create initial user document
        return this.setUserDoc(user.user, data)
          .then(() => {
            this.router.navigate(['/'])
        })
      })
      .catch(error => this.handleError(error) )
  }

  // Email/Password Login
  emailLogin(email: string, password: string) {
    console.log("email ------->"+ email);
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .then(() => {

        this.router.navigate(['/'])

      })
      .catch(error => this.handleError(error))
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {

    let data = {
      email: email,
      reset_link: environment.redirectUrl+"/reset/"+btoa(email)
    }

      const forgotPassword = this.aff.httpsCallable('forgotPassword')
      return forgotPassword(data).toPromise()
      .then(result => {
        if (result.isSuccess) {
          return true;
        } else {
          return false;
        }
      })
      .catch(error => {
        this.handleError(error);
        return false;
      })
  }

    // change password
    changePassword(email: string, password: any) {
      let data = {
        email: email,
        password: password
      }

      const updatePassword = this.aff.httpsCallable('updatePassword')
      return updatePassword(data).toPromise()
      .then(result => {
        // console.log("abcd "+result)
        if (result.isSuccess) {
          return true;
        } else {
          this.handleError("Something went wrong!");
          return false;
        }
      })
      .catch(error => {
        console.log(error)
        this.handleError(error);
        return false;
      })
    }

  // Signout
  signOut() {
    this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('group-org');
      localStorage.removeItem('current-role');
      this.router.navigate(['/login'])
    })
  }

  // ADMIN USER CREATION
  adminCreateUser(data) {
    const createUser = this.aff.httpsCallable('createUser')
    return createUser(data).toPromise()
      .then(result => {
        if (result.isSuccess) {
          return this.setUserDoc(result, data)
        } else {
          console.log('good result, bad error')
          console.log(result)
          return new Promise<void>((resolve, reject) => reject(result))
        }
      })
      .catch(error => {
        console.log('bad result')
        console.log(error)
        return new Promise<void>((resolve, reject) => reject(error))
      })
  }

  //check for user role
  checkForUserRole(uid: string, orgId: string): Observable<any[]> {
    return this.afs.collection<any>('org_members', ref => ref.where('uid', '==', uid).where('orgId', '==', orgId)).valueChanges();
  }
}
