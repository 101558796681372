import { NgModule, Optional, SkipSelf } from '@angular/core'

import { SettingsService } from './settings/settings.service'
import { ThemesService } from './themes/themes.service'
import { TranslatorService } from './translator/translator.service'

import { AlertService } from './alerts/alert.service'
import { AuthGuard, AuthService, ComplianceGuard, LoginGuard, ProfileService } from './auth'
import { DeviceDosageCalculationService,CommandService, DeviceService, DeviceStateService, FirmwareService } from './device'
import { ManualsService } from './operating-manuals'
import { MenuService } from './menu/menu.service'
import { NotifyService } from './notify/notify.service'
import { OrgService, OrganizationDeviceStateService } from './organization'
import { TermsComplianceService, TermsService } from './terms-of-service'
import { UserService } from './user/user.service'

import { throwIfAlreadyLoaded } from './module-import-guard'

@NgModule({
  providers: [
    SettingsService,
    ThemesService,
    TranslatorService,
    MenuService,
    NotifyService,
    AuthService,
    AuthGuard,
    ComplianceGuard,
    LoginGuard,
    ProfileService,
    AlertService,
    CommandService,
    DeviceService,
    DeviceStateService,
    DeviceDosageCalculationService,
    FirmwareService,
    ManualsService,
    OrgService,
    OrganizationDeviceStateService,
    TermsComplianceService,
    TermsService,
    UserService,
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule')
  }
}
