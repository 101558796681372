import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {firestore} from 'firebase';
import { uniq } from 'lodash'
import { Observable, combineLatest, of } from 'rxjs'
import { tap, map, switchMap, auditTime } from 'rxjs/operators'
import {NotifyService} from '../notify/notify.service';
import {AuthService, ProfileService} from '../auth';
import {User, OrgMembers} from '../../models';

@Injectable()
export class UserService {
  collection: AngularFirestoreCollection<User>;
  list: Observable<User[]>;
  memberCollection: AngularFirestoreCollection<OrgMembers>
  memberList: Observable<OrgMembers[]>
  filteredUsers = []

  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private auth: AuthService,
    private profile: ProfileService,
    private notify: NotifyService,
  ) {
    //user collection
    this.collection = afs.collection<User>('users');
    this.list = this.collection.valueChanges();

    //org_members collection
    this.memberCollection = afs.collection<OrgMembers>('org_members');
    this.memberList = this.memberCollection.valueChanges();
  }
  //all users
  getAll(): Observable<User[]> {
    return this.list;
  }
  //user by organization
  getByOrg(orgId: string): Observable<User[]> {
    return this.list.pipe(
      tap(() => {}),
      map((users) => users.filter((user) => user.orgId === orgId)),
      tap((users) => {}),
    );
  }

  // get user count by organization

  async getCountsByOrg(collection, Id) {
    const col = await this.afs
      .collection<any>(collection, (ref) => ref.where('orgId', '==', Id))
      .get()
      .toPromise();
    let size = col.size;
    if (collection == 'users') {
      const membersCollection = await this.afs
        .collection<any>('org_members', (ref) => ref.where('orgId', '==', Id))
        .get()
        .toPromise();
      size += membersCollection.size;
    }
    return size;
  }

  //create user
  add(item: User) {
    item.createdAt = firestore.FieldValue.serverTimestamp();
    item.createdBy = this.profile.uid;
    item.receivesAlerts = false;
    //assign default operator role
    // item.role = 0;
    return this.auth.adminCreateUser(item);
  }

  // If error, console log and notify user
  private handleError(error) {
    console.error(error);
    this.notify.update(error.message, 'warning');
  }
  //update user profile
  async updateProfile(item: User, uuid: string) {

    //update email into firebase
    let data = {
      sourceEmail: item.email,
      uuid: uuid
    }

    const updateEmail = this.aff.httpsCallable('updateEmail')

    return await updateEmail(data).toPromise()
      .then(result => {
        if (result.isSuccess) {
          //update into firebase collection
          item.updatedAt = firestore.FieldValue.serverTimestamp()
          item.updatedBy = this.profile.uid
          this.collection.doc(uuid).update(item)
          //update into firebase collection
          return true;
        } else if(!result.isSuccess) {
          this.handleError(result.msg.errorInfo);
          return false;
        }
      })
      .catch(error => {
        this.handleError(error);
        return false;
      })
  }

  async update(item: User) {
    item.updatedAt = firestore.FieldValue.serverTimestamp();
    item.updatedBy = this.profile.uid;
    this.collection.doc(item.uid).update(item);
  }
  //remove user
  delete(itemId: string) {
    return this.collection.doc(itemId).delete();
  }

  userInfoChanges(uid: any) {
    const doc = this.collection.doc(uid);

    const observer = doc.snapshotChanges().pipe(
      map((action: any) => {
        const data = action.payload.data() as any;
        const id = action.payload.metadata;

        return data;
        // return  action
      }),
    );

    return observer;
  }

  getRoleInfo(orgId: string, uid: string): Observable<OrgMembers[]> {
    return this.memberList.pipe(
      map((orgMembers) => orgMembers.filter((orgMember) => (orgMember.orgId === orgId && orgMember.uid === uid))),
      tap((orgMembers) => console.log(orgMembers)),
    );
  }

  getMemberByOrg(orgId: string): Observable<User[]> {
    return this.list.pipe(
      tap(() => console.log(orgId)),
      map((users) => users.filter((user) => user.orgId === orgId)),
      tap((users) => console.log(users)),
    );
  }

  resendInvitationEmail(uid: string,email: string) {

    let data = {
      uuid: uid,
      email: email
    }

      const  resendInvitationEmail = this.aff.httpsCallable('resendInvitationEmail')
      return resendInvitationEmail(data).toPromise()
      .then(result => {
        if (result.isSuccess) {
          return true;
        } else {
          return false;
        }
      })
      .catch(error => {
        this.handleError(error);
        return false;
      })
  }

  //get members by uid
  getOrgMembersByUid(uid: string, orgId: string):Observable<any[]> {
    return this.afs.collection<OrgMembers>('org_members', ref => ref.where('uid', '==', uid)).valueChanges()
      .pipe(
        switchMap((members: any[]) => {
          const memberOrgIds = uniq(members.map(member => member.orgId))
          memberOrgIds.indexOf(this.profile.org) == -1 ? memberOrgIds.push(this.profile.org):''
          memberOrgIds.indexOf(orgId) > -1 ? memberOrgIds.splice(memberOrgIds.indexOf(orgId), 1):''

          return (members.length === 0 && memberOrgIds.length === 0) ? Observable.of([]):combineLatest(
            of(members),
            combineLatest(
              memberOrgIds.map(orgzId => this.getAllMembers(orgzId)
              )
            )
          )
        }),
        map(([members, users]) => {
          return users == undefined ? []:users.map(user => {
            user.users.map(u => {
              this.filteredUsers.findIndex(uInfo => uInfo.uid == u.uid) == -1 ? this.filteredUsers.push(u):''
            })

            user.extraAdded.map(u => {
              this.filteredUsers.findIndex(uInfo => uInfo.uid == u.uid) == -1 ? this.filteredUsers.push(u):''
            })

            return this.filteredUsers
          })
        })
      )
  }

  //get all users
  getAllMembers(orgId: string) {
    return this.afs.collection<OrgMembers>('users', ref => ref.where('orgId', '==', orgId)).valueChanges()
      .pipe(
        switchMap(members => {
          return combineLatest(
            of(members),
            combineLatest(
              this.getExtraOrgMembers(orgId)
            )
          )
        }),
        map(([members, users]) => {
          return {
            users: members,
            extraAdded: users.length === 0 ? []:users[0]
          }
        })
      )
  }

  //get extra addd org members by orgId
  getExtraOrgMembers(orgId: string) {
    return this.afs.collection<OrgMembers>('org_members', ref => ref.where('orgId', '==', orgId)).valueChanges()
      .pipe(auditTime(1000),
        switchMap(members => {
          const memberIds = uniq(members.map(member => member.uid))

          return memberIds.length === 0 ? Observable.of([]):combineLatest(
            of(members),
            combineLatest(
              memberIds.map(uid =>
                this.afs.collection<User>('users', ref => ref.where('uid', '==', uid)).valueChanges().pipe(
                  map(users => users.length == 0 ? []:users[0])
                )
              )
            )
          )
        }),
        map(([members, users]) => {
          //if org members are not added from other orgs then return or fetch one's info
          return (members == undefined && users == undefined) ? []:users.map(user => {
            return {
              ...user,
              memberInfo: members.find(member => member.uid === user.uid),
            }
          })
        })
      )
  }

  //add new member from already existed members
  addMember(item: OrgMembers): Promise<void> {
    item.id = this.afs.createId()
    item.createdAt = new Date()
    return this.memberCollection.doc(item.id).set(item)
  }

  //update role
  updateMemberRole(itemId: string, role: number) {
    return this.memberCollection.doc(itemId).update({role: role});
  }

  //check if user exist in other org
  ifExistInAnother(userId): Observable<any[]> {
    return this.afs.collection('org_members', ref => ref.where('uid', '==', userId)).valueChanges().pipe(auditTime(1000))
  }

  //update orgId
  updateOrgId(itemId: string, orgId: string, role: number) {
    return this.collection.doc(itemId).update({orgId: orgId, role: role});
  }

  //update member orgId
  updateMemberOrgId(itemId: string, orgId: string, role: number) {
    return this.memberCollection.doc(itemId).update({orgId: orgId, role: role});
  }

  //delete org member
  deleteMember(Id: string): Promise<any> {
    return this.memberCollection.doc(Id).delete()
  }

  //user not in orgaization
  getNotInOrg(orgId: string): Observable<User[]> {
    return this.list.pipe(
      auditTime(1000),
      tap(() => orgId),
      map(users => users.filter(user => user.orgId !== orgId)),
      tap(users => users)
    )
  }

  //fetch all members by uid
  getAllMembersWithOrgsByUid(uid: string, orgId: string, role: number): Observable<any[]> {
    return this.afs.collection<any>('org_members', ref => ref.where('uid', '==', uid)).valueChanges()
      .pipe(auditTime(1000),
        switchMap(members => {
          const memberOrgIds = uniq(members.map(member => member.orgId))
          memberOrgIds.push(orgId)

          return memberOrgIds.length === 0 ? Observable.of([]):combineLatest(
            of(members),
            combineLatest(
              memberOrgIds.map(orgId =>
                this.afs.collection<User>('orgs', ref => ref.where('id', '==', orgId)).valueChanges().pipe(
                  map(orgs => orgs[0])
                )
              )
            )
          )
        }),
        map(([members, orgs]) => {
          //if org members are not added from other orgs then return or fetch one's info
          return (members == undefined && orgs == undefined) ? []:orgs.map(org => {
            return {
              ...org,
              role: members.find(member => member.orgId === org.id) == undefined ? role:members.find(member => member.orgId === org.id).role
            }
          })
        })
      )

  }

}
