import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { AuthService } from '../../core/auth/auth.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [`
    .authentication-wrapper {
      display: flex;
      flex-basis: 100%;
      min-height: 100vh;
      width: 100%;
    }
    .authentication-inner {
      width: 100%;
      max-width: 300px;
    }
    .authentication-1 {
      align-items: center;
      justify-content: center;
    }
  `]
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup

  constructor(
    public fb: FormBuilder,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.loginForm = this.fb.group({
      'email': ['', [Validators.required, Validators.email]],
      'password': ['', [
        // Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(4),
        Validators.maxLength(25),
        Validators.required
      ]]
    })
  }

  get email() { return this.loginForm.get('email') }
  get password() { return this.loginForm.get('password') }

  login() {

        return this.auth.emailLogin(this.email.value, this.password.value)
  }

  refreshMenu(){
    window.location.reload();

  }
}
