import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from '../../core/auth/auth.service'
import { NotifyService } from '../../core/notify/notify.service'

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styles: [`
    .authentication-wrapper {
      display: flex;
      flex-basis: 100%;
      min-height: 100vh;
      width: 100%;
    }
    .authentication-inner {
      width: 100%;
      max-width: 300px;
    }
    .authentication-1 {
      align-items: center;
      justify-content: center;
    }
  `]
})
export class ResetComponent implements OnInit {

  form: FormGroup
  msg = null
  passwordMatched = true
  userEmail = "";

  constructor(
    public fb: FormBuilder,
    public auth: AuthService,
    public route: ActivatedRoute,
    private router: Router,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    this.userEmail = atob(this.route.snapshot.paramMap.get('encrypted_id'));
    this.form = this.fb.group({
      'password': ['', [Validators.required, Validators.minLength(6)]],
      'confirmPassword': ['', [Validators.required]]
    })
  }

  get password() { return this.form.get('password') }

  validatePassword() { 
    return this.passwordMatched = this.form.value.password == this.form.value.confirmPassword ? true:false 
  }

  //update user password
  async confirm() {
    if(this.passwordMatched) {
      try {
        if(await this.auth.changePassword(this.userEmail, this.form.value.password)) {
          this.msg = 'Your password has been successfully changed. Please login and proceed further.';
          this.notify.update(this.msg, 'success')
          //redirect login
          this.router.navigate(['/login'])
        } else {
          this.msg = 'An error occured. Please contact your Far UV support representative.'
        }
      } catch (err) {
        this.msg = 'An error occured. Please contact your Far UV support representative.'
      }
    }
  }

}
