export const deviceStatus = { online: 0, stuck: 1, offline: 2 }

export const sectionIndexing = [
  {Schedules: { sectionName: 'Schedules', indexValue: 0, description: 'Schedules' }},
  {Notifications: { sectionName: 'Notifications', indexValue: 1, description: 'Notifications' }},
  {Users: { sectionName: 'Users', indexValue: 2, description: 'Users' }},
]

export const newLabel = [
  {Schedules: { sectionName: 'Schedules', indexValue: 0, description: 'Schedules' }},
  {Notifications: { sectionName: 'Notifications', indexValue: 1, description: 'Notifications' }},
  {Users: { sectionName: 'Users', indexValue: 2, description: 'Users' }},
  {Groups: { sectionName: 'Groups', indexValue: 3, description: 'Groups' }},
]

export const scheduleAndNotificationDocs = 'section_introduction/Air_Sniper_Notifications_and_Schedules_Launch.docx.pdf';

export const maintenanceType = [
  { name: 'Filter maintenance', notification_type: 3, search_for: 'filter' },
  { name: 'Functional diagnostic', notification_type: 5, search_for: 'diagnostic' },
  { name: 'Bulb replacement', notification_type: 2, search_for: 'bulb' }
]

export const notificationType = { 
  device_offline: 0, 
  device_on_off: 1, 
  bulb_hrs: 2,
  filter_percentage: 3,
  device_maintenance: 4 
}

export const userRoles = {
  Operator: 0,
  Manager: 1,
  OrgOwner: 2,
  SysAdmin: 3
}

export const threeLevels = { 
  org: 1,
  group: 2,
  device: 3 
}

export const threeLevelsReverse = { 
  1: 'Organization',
  2: 'Group',
  3: 'Device' 
}

export const DEVICE_TYPES_WITH_FANS = ['ULTRA', 'ELITE', 'PRO', 'TOWER', 'HYBRID 10-24']

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];