import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {firestore} from 'firebase';
import {Observable, ReplaySubject, combineLatest} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {Command, CommandLog, Device, DeviceConnectionEvent} from '../../models';
import {ProfileService} from '../auth/profile.service';

const deviceImages = {
  ELITE: 'assets/img/device/EliteFront.jpg',
  INFLOW: 'assets/img/device/InFlow2020-2.jpg',
  PRO: 'assets/img/device/ProFront.jpg',
  TOWER: 'assets/img/device/TowerProd1.jpg',
  ULTRA: 'assets/img/device/Ultra2B.jpg',
  KRYPTON: 'assets/img/device/uv-icon.png'
};

@Injectable()
export class DeviceService {
  collection: AngularFirestoreCollection<Device>;
  list: Observable<Device[]>;
  orgId$ = new ReplaySubject<string>();
  org: Observable<any>;
  orgList: Observable<Device[]>;

  constructor(private afs: AngularFirestore, private profileService: ProfileService) {
    this.collection = afs.collection<Device>('devices');

    this.list = this.collection.valueChanges();

    this.orgList = this.orgId$.pipe(
      switchMap((orgId) =>
        afs
          .collection<Device>('devices', (ref) => ref.where('orgId', '==', orgId).orderBy('name'))
          .valueChanges(),
      ),
      map((devices) => {
        devices.map((device) => {
          device.image = deviceImages['KRYPTON'];

          if (device.maintenance) {
            device.maintenance.sort((a, b) => {
              return a.at.seconds < b.at.seconds ? 1 : -1;
            });
          }
        });
        return devices;
      }),
    );

    this.org = this.orgId$.pipe(switchMap((orgId) => afs.doc<any>(`orgs/${orgId}`).valueChanges()));
  }
  //fetch all devices
  getAll(): Observable<Device[]> {
    return this.list;
  }
  //fetch device by org
  getAllByOrg(orgId): Observable<Device[]> {
    this.orgId$.next(orgId);
    return this.orgList;
  }
  //fetch org
  getOrg(): Observable<any> {
    return this.org;
  }
  //fetch selected device
  getDevice(id): Observable<Device> {
    return this.afs
      .doc<Device>(`devices/${id}`)
      .valueChanges()
      .pipe(
        map((device) => {
          // device.image = device.vers != undefined ? deviceImages[device.vers.vers_am] : 'assets/img/device/Ultra1.jpg';
          device.image = deviceImages['KRYPTON'];
          
          if (device.maintenance) {
            device.maintenance.sort((a, b) => {
              return a.at.seconds < b.at.seconds ? 1 : -1;
            });
          }

          return device;
        }),
      );
  }


  //fetch device getStateLogs
  getStateLogs( id): Observable<any[]> {
    
    return this.collection
      .doc(id)
      .collection<DeviceConnectionEvent>('stateLog')
      .valueChanges()
      .pipe(
        map((arr) => {
          return arr
          // return arr.map((log) => {
          //   return {
          //     eventAt: log.eventAt,
          //     socketId: log.socketId,
          //     event: log.event,
          //   };
          // });
        }),
      );
  }

  

  //stop snoozing
  stopSnooze(deviceId: string, notificationType: number, data: any) {
    //device offlilne
    if (notificationType == 0) {
      return this.afs.doc(`devices/${deviceId}`).update({
        deviceOfflineStats: data,
      });
    } else if (notificationType == 1) {
      //power on off
      return this.afs.doc(`devices/${deviceId}`).update({
        deviceOnOffStats: data,
      });
    } else if (notificationType == 2) {
      //bulb hours
      return this.afs.doc(`devices/${deviceId}`).update({
        bulbNotificationStats: data,
      });
    } else if (notificationType == 3) {
      //filter percentage
      return this.afs.doc(`devices/${deviceId}`).update({
        filterNotificationStats: data,
      });
    } else if (notificationType == 4) {
      //device maintenance
      return this.afs.doc(`devices/${deviceId}`).update({
        maintenanceNotificationStats: data,
      });
    }
  }

  //fetch connection logs Data for calculation
  getConnectionData( id, connectedTime, disconnectedTime): Observable<any[]> {
    return this.collection
      .doc(id)
      .collection<DeviceConnectionEvent>('connectionLog', (ref) => ref.where('eventAt', '<=', disconnectedTime)
      .where('eventAt', '>=', connectedTime).orderBy('eventAt', 'asc').limit(200))
      .valueChanges()
      .pipe(
        map((arr) => {
          return arr.map((log) => {
            return {
              eventAt: log.eventAt,
              socketId: log.socketId,
              event: log.event,
            };
          });
        }),
      );
  }


  //fetch connection logs Data for calculation
  getModelLogData( id): Observable<any[]> {
    
    return this.collection
      .doc(id)
      .collection<DeviceConnectionEvent>('stateLog', (ref) => ref.orderBy('at', 'desc').limit(1))
      .valueChanges()
      .pipe(
        map((arr) => {
          return arr;
        }),
      );
  }

  //fetch logs Data for calculation
  getModelLog( id,connectedTime, disconnectedTime ): Observable<any[]> {
  return this.collection
    .doc(id)
    .collection<DeviceConnectionEvent>('stateLog', (ref) => 
      ref.where('at', '<=', disconnectedTime).where('at', '>=', connectedTime).orderBy('at', 'asc')
    )
    .valueChanges()
    .pipe(
      map((arr) => {
        return arr.map((stateLog, index) => {
          if(stateLog.bulbOnOff == '1'){
            return  stateLog;
          }else{
            return  null;
          }
        });
      }),
    );
  }


  // Get Day first state log for 8 hours calculation
  getDayFirstStateLog( id,connectedTime, disconnectedTime ): Observable<any[]> {
    return this.collection
      .doc(id)
      .collection<DeviceConnectionEvent>('stateLog', (ref) => 
        ref.where('at', '<=', disconnectedTime).where('at', '>=', connectedTime) 
      )
      .valueChanges()
      .pipe(
        map((arr) => {
          return arr.map((stateLog, index) => {
            if(stateLog.bulbOnOff == '1'){
              return  stateLog;
            }else{
              return  null;
            }
          });
        }),
      );
    }

  //Get last 24 hours all connection logs
  getConnectionLogs(id): Observable<any[]> {
    return this.collection
      .doc(id)
      .collection<DeviceConnectionEvent>('connectionLog', (ref) => ref.orderBy('eventAt', 'desc').limit(200))
      .valueChanges()
      .pipe(
        map((arr) => {
          return arr.map((log) => {
            return {
              eventAt: log.eventAt,
              socketId: log.socketId,
              event: log.event ? 'Connect' : 'Disconnect',
            };
          });
        }),
      );
  }
  //fetch wifi errors
  getWifiErrors(id): Observable<any[]> {
    return this.afs
      .collection<any>('wifiErrors', (ref) => ref.where('serial', '==', id).orderBy('at', 'desc').limit(200))
      .valueChanges()
      .pipe(
        map((arr) => {
          return arr.map((log) => {
            return {
              eventAt: log.at,
              event: `${log.reason},${log.offline_time},${log.wifi_reset_count},${log.wifi_total_connect_fail_count},${log.wifi_total_dhcp_fail_count},${log.wifi_total_dns_fail_count},${log.wifi_total_socket_fail_count}, dropcounts ,${log.wifi_total_network_drop_count},${log.wifi_total_interface_drop_count},${log.wifi_total_socket_drop_count},${log.wifi_total_pingpong_drop_count}`,
              socketId: '',
            };
          });
        }),
      );
  }

  //fetch connection logs
  getConnectionEvents(id): Observable<any[]> {
    return this.collection
      .doc(id)
      .collection<DeviceConnectionEvent>('connectionLog', (ref) => ref.orderBy('eventAt', 'desc').limit(200))
      .valueChanges()
      .pipe(
        map((arr) => {
          return arr.map((log) => {
            return {
              eventAt: log.eventAt,
              socketId: log.socketId,
              event: log.event ? 'Connect' : 'Disconnect',
            };
          });
        }),
      );
  }

  //fetch selected logs
  getEventLog(id): Observable<any[]> {
    const logs = combineLatest<any[]>(this.getConnectionEvents(id), this.getWifiErrors(id)).pipe(
      map((arr) => arr.reduce((acc, cur) => acc.concat(cur))),
      map((arr) => arr.sort((a, b) => (a.eventAt.toDate() > b.eventAt.toDate() ? -1 : 1))),
      map((arr) => arr.slice(0, 200)),
    );

    return logs;
  }
  //fetch logs for command
  getCommandLog(id): Observable<CommandLog[]> {
    return this.collection
      .doc(id)
      .collection<CommandLog>('commandLog', (ref) => ref.orderBy('sentAt', 'desc').limit(200))
      .valueChanges();
  }
  //fetch logs for response
  getResponseLog(id): Observable<CommandLog[]> {
    return this.collection
      .doc(id)
      .collection<CommandLog>('responseLog', (ref) => ref.orderBy('sentAt', 'desc').limit(200))
      .valueChanges();
  }
  //fetch terminal logs
  getTerminalLog(id, limit = 200): Observable<CommandLog[]> {
    const logs = combineLatest<CommandLog[]>(this.getCommandLog(id), this.getResponseLog(id)).pipe(
      map((arr) => arr.reduce((acc, cur) => acc.concat(cur))),
      map((arr) => arr.sort((a, b) => (a.sentAt.toDate() > b.sentAt.toDate() ? -1 : 1))),
      map((arr) => arr.slice(0, limit)),
    );

    return logs;
  }

  // CUD ACTIONS
  set(item: Device) {
    return this.collection.doc(item.serial).set(
      {
        ...item,
        createdAt: firestore.FieldValue.serverTimestamp(),
        linkedAt: new Date(),
        createdBy: this.profileService.uid,
      },
      {merge: true},
    );
  }

  updateName(serial: string, name: string) {
    return this.update(serial, {name: name});
  }

  updateKey(serial: string, key: string, value: any) {
    return this.update(serial, {[key]: value});
  }

  updateMaintenance(event) {
    const maintenance = {...event};
    maintenance.at = firestore.Timestamp.fromDate(event.at);
    return this.update(event.serial, {maintenance: firestore.FieldValue.arrayUnion(maintenance), maintenanceNotificationStats: []});
  }

  assignGroup(serial: string, groupName: string) {
    return this.update(serial, {groupName: groupName});
  }

  unassignGroup(serial: string) {
    return this.update(serial, {groupName: firestore.FieldValue.delete()});
  }

  unassignOrg(serial: string) {
    return this.update(serial, {orgId: firestore.FieldValue.delete(), linkedAt: null});
  }

  update(serial: string, item: any) {
    return this.collection.doc(serial).update(item);
  }

  getUser(serial: string){
    return this.collection.doc(serial).get();
  }

  delete(serial: string) {
    return this.collection.doc(serial).delete();
  }

  // SEND COMMAND
  sendCommand(command: Command) {
    return this.afs.collection(`commandQueue`).add({...command, sentAt: firestore.Timestamp.now()});
  }

  updateGroup(serial: string, groupId: string) {
    return this.update(serial, { groupName: groupId })
  }

  removeGroup(serial: string) {
    return this.collection.doc(serial).update({
      groupName: firestore.FieldValue.delete()
    })
  }
}
