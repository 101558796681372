import { Injectable } from '@angular/core'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'
import { AngularFireFunctions } from '@angular/fire/functions'
import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators'
import { Organization } from '../../models'
import { OrgMembers } from '../../models'

@Injectable()
export class OrgService {

  collection: AngularFirestoreCollection<Organization>
  list: Observable<Organization[]>

  memberCollection: AngularFirestoreCollection<OrgMembers>
  memberList: Observable<OrgMembers[]>

  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
  ) {
    //orgs info
    this.collection = afs.collection<Organization>('orgs')
    //member info
    this.memberCollection = afs.collection<OrgMembers>('org_members')
    this.list = this.collection.valueChanges().pipe(
      map(orgs => orgs.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    )
    this.memberList = this.memberCollection.valueChanges()
  }
  //fetch all organization
  getAll(): Observable<Organization[]> {
    return this.list
  }

  //fetch extra added members
  getAddedMembers(): Observable<OrgMembers[]> {
    return this.memberList
  }

  //fetch organization
  fetchOrganization(orgId): Observable<any[]> {
    return this.afs.collection<any>('orgs', ref => ref.where('id', '==', orgId)).valueChanges();    
  }

  //fetch all organization members
  getAllMembers(orgId): Observable<any[]> {
    return this.afs.collection<any>('org_members', ref => ref.where('orgId', '==', orgId))
    .valueChanges()
    .pipe(
      map(members => {
        return members
      })
    )
  }

  //fetch all members by uid
  getAllMembersByUid(uid): Observable<any[]> {
    return this.afs.collection<any>('org_members', ref => ref.where('uid', '==', uid))
    .valueChanges()
    .pipe(
      map(members => {
        return members
      })
    )
  }

  //fetch selected organization
  getOrganization(id: string): Observable<Organization> {
    return this.collection.doc<Organization>(id).valueChanges() 
  }
  //add new organization
  add(item: Organization): Promise<void> {
    item.id = this.afs.createId()
    item.createdAt = new Date()
    item.userCount = 0
    item.deviceCount = 0
    return this.collection.doc(item.id).set(item)
  }
  //add new member from already existed members
  addMember(item: OrgMembers): Promise<void> {
    item.id = this.afs.createId()
    item.createdAt = new Date()
    console.log(item)
    return this.memberCollection.doc(item.id).set(item)
  }
  //update organization
  update(item: Partial<Organization>): Promise<void> {
    return this.collection.doc(item.id).update(item)
  }
  //delete organization
  delete(orgId: string): Promise<any> {
    const deleteOrganization = this.aff.httpsCallable('deleteOrganization')
    return deleteOrganization({ orgId }).toPromise()
  }
  //delete org member
  deleteMember(Id: string): Promise<any> {
    return this.memberCollection.doc(Id).delete()
  }
}
