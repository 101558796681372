import { Component, OnInit, ViewChild } from '@angular/core'
import {Location} from '@angular/common'
import { DatePipe } from "@angular/common";
const screenfull = require('screenfull')
const browser = require('jquery.browser')
declare var $: any

import { UserblockService } from '../sidebar/userblock/userblock.service'
import { SettingsService } from '../../core/settings/settings.service'
import { MenuService } from '../../core/menu/menu.service'
import { AuthService } from '../../core/auth/auth.service'
import { AlertService } from '../../core/alerts/alert.service'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navCollapsed = true // for horizontal layout
  menuItems = [] // for horizontal layout

  isNavSearchVisible: boolean
  @ViewChild('fsbutton') fsbutton  // the fullscreen button

  constructor(
    public menu: MenuService,
    public userblockService: UserblockService,
    public settings: SettingsService,
    public auth: AuthService,
    public alerts: AlertService,
    private _location: Location,
    public datepipe: DatePipe
  ) {

    // show only a few items on demo
    this.menuItems = menu.getMenu().slice(0, 4) // for horizontal layout

  }

  ngOnInit() {
    this.isNavSearchVisible = false
    if (browser.msie) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none'
    }
  }

  toggleUserBlock(event) {
    event.preventDefault()
    this.userblockService.toggleVisibility()
  }

  backToPrevious() {
    this._location.back();
  }

  openNavSearch(event) {
    event.preventDefault()
    event.stopPropagation()
    this.setNavSearchVisible(true)
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat)
    this.isNavSearchVisible = stat
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen')
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed')
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText')
  }

  toggleFullScreen(event) {

    if (screenfull.enabled) {
      screenfull.toggle()
    }
    // Switch icon indicator
    const el = $(this.fsbutton.nativeElement)
    if (screenfull.isFullscreen) {
      el.children('em').removeClass('fa-expand').addClass('fa-compress')
    } else {
      el.children('em').removeClass('fa-compress').addClass('fa-expand')
    }
  }

  //check for alert length
  _checkLength(alerts) {
    let totalAlerts = 0;
    for (const [key, value] of Object.entries(alerts)) {
      //if no snoze set
      if(value['snoze'] == undefined) {
        totalAlerts++;

      } else if(value['snoze'] != undefined) {
        //if snoze set than check for snoze time
        let snozeHours2 = new Date(this.datepipe.transform(value['snoze'].toDate(), "MM/dd/yyyy HH:mm"));
        let currentHours2 = new Date();

        if(currentHours2 > snozeHours2) {
          totalAlerts++;
        }

      }

    }

    return totalAlerts;
  }

  //manage alerts
  _alertAlowed(alert) {
    let returnStatus = true;
    //check for snoze time
    if(alert.snoze != undefined) {
      let snozeHours = new Date(this.datepipe.transform(alert.snoze.toDate(), "MM/dd/yyyy HH:mm"));
      let currentHours = new Date();

      if(currentHours <= snozeHours) {
        returnStatus = false;
      }
    }

    return returnStatus;
  }
}
