
import { CanActivate, CanActivateChild } from '@angular/router';
import {AuthGuard, ComplianceGuard, LoginGuard} from '../core/auth/auth.guard';
//import system admin guard
import {SystemAdminGuard} from '../core/guard/system-admin/system-admin.guard';
import {LayoutComponent} from '../layout/layout.component';
import {LoginComponent} from './auth-routes/login.component';
import {ForgotComponent} from './auth-routes/forgot.component';
import {ResetComponent} from './auth-routes/reset.component';
import {StopRemindersComponent} from './auth-routes/stop-snooze-notification/stop-reminder-notification.component';

export const routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
     children: [
      {path: '', redirectTo: 'dashboard',  pathMatch: 'full'},
      {path: 'dashboard',  canActivate: [ComplianceGuard], loadChildren: './dashboard/dashboard.module#DashboardModule'},
      {path: 'operating-manuals' , loadChildren: './operating-manuals/operating-manuals.module#OperatingManualsModule'},
      {path: 'terms-of-service'  ,loadChildren: './terms-of-service/terms-of-service.module#TermsOfServiceModule'},
      {path: 'groups', data:{allowedRoles : [1,2,3]}, canActivate: [SystemAdminGuard], loadChildren: './groups/groups.module#GroupsModule'},
      {path: 'users' , data:{allowedRoles : [1,2]}, canActivate: [SystemAdminGuard], loadChildren: './users/users.module#UsersModule'},
      {path: 'settings2', loadChildren: './org-settings/org-settings.module#OrgSettingsModule'},
      {path: 'settings', data:{allowedRoles : [1,2,3]} , canActivate: [SystemAdminGuard], loadChildren: './settings/settings.module#SettingsModule'},
      {path: 'sysadmin', data:{allowedRoles : [3]}, canActivate: [SystemAdminGuard], loadChildren: './sysadmin/admin.module#AdminModule'},
    ]
  },

  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: 'forgot', component: ForgotComponent, canActivate: [LoginGuard]},
  {path: 'reset/:encrypted_id', component: ResetComponent, canActivate: [LoginGuard]},
  {path: 'stop-reminder/:notification_id/:user_id', component: StopRemindersComponent},

  // Not found
  {path: '**', redirectTo: 'dashboard'},
];
