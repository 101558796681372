import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { firestore } from 'firebase'
import { Command } from '../../models'
import { Router } from '@angular/router'

@Injectable()
export class CommandService {

  constructor(
    private afs: AngularFirestore,
    private router: Router
    ) {}
  //add command to queue
  send(command: Command) {
    return this.afs.collection('commandQueue').add({ ...command, sentAt: firestore.Timestamp.now()})
  }

  reloadComponent(orgId?: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    orgId == undefined || orgId == null ? this.router.navigate(['/dashboard']):this.router.navigate(["/dashboard/orgs/"+orgId]);
  }
}
