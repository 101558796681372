import { Injectable } from '@angular/core'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore'
import { firestore } from 'firebase'
import { Observable } from 'rxjs/Observable'
import { ProfileService } from '../auth/profile.service'
import { Firmware, FirmwareFile } from '../../models'

@Injectable()
export class FirmwareService {

  private FIRMWARE_COL_NAME = 'firmware'
  private FIRMWARE_FILES_COL_NAME = 'files'
  private list: Observable<Firmware[]>
  collection: AngularFirestoreCollection<Firmware>;

  constructor(
    private afs: AngularFirestore,
    private profile: ProfileService,
  ) {
    this.list = this.afs.collection<Firmware>(this.FIRMWARE_COL_NAME).valueChanges()
    this.collection = afs.collection<Firmware>('firmware');
  }
  //fetch all
  getAll(): Observable<Array<Firmware>> {
  
     return this.list
  }
  //add new firmware
  add(firmware: Firmware, image1: FirmwareFile, image2: FirmwareFile): Promise<void> {
    firmware.createdAt = new Date()
    firmware.createdBy = this.profile.uid

    const batch = firestore().batch()
    batch.set(this.afs.doc(`${this.FIRMWARE_COL_NAME}/${firmware.version}`).ref, firmware)
    batch.set(this.afs.doc(`${this.FIRMWARE_COL_NAME}/${firmware.version}/${this.FIRMWARE_FILES_COL_NAME}/image1`).ref, image1)
    batch.set(this.afs.doc(`${this.FIRMWARE_COL_NAME}/${firmware.version}/${this.FIRMWARE_FILES_COL_NAME}/image2`).ref, image2)
 
    return batch.commit()
  }
  //remove firmware
  delete(firmwareId: string): Promise<void> {
    const batch = firestore().batch()
    batch.delete(this.afs.doc(`${this.FIRMWARE_COL_NAME}/${firmwareId}/${this.FIRMWARE_FILES_COL_NAME}/image1`).ref)
    batch.delete(this.afs.doc(`${this.FIRMWARE_COL_NAME}/${firmwareId}/${this.FIRMWARE_FILES_COL_NAME}/image2`).ref)
    batch.delete(this.afs.doc(`${this.FIRMWARE_COL_NAME}/${firmwareId}`).ref)
    return batch.commit()
  }

  updateFirmware(fId:string, data: any) {
    this.collection.doc(fId).update(data);
  }

}
