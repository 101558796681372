export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDAe1NpegVu1daRZg6EiEk4i3Yaetz4i_8',
    authDomain: 'production---far-uv.firebaseapp.com',
    projectId: 'production---far-uv',
    storageBucket: 'production---far-uv.appspot.com',
    messagingSenderId: '920395183059',
    appId: '1:920395183059:web:df8531fceb17185d60b526',
    measurementId: 'G-SQWPKPKV09',
  },
  redirectUrl: 'https://faruvweb.lusites.xyz',
  sentryUrl: 'https://72aabe388a424d2e80c9b23fba9394bc@o405624.ingest.sentry.io/5274114',
};
