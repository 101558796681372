import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AlertService} from '../../../core/alerts/alert.service';
import {DeviceService} from '../../../core/device/device.service';
import {NotifyService} from '../../../core/notify/notify.service';

@Component({
  selector: 'app-stop-reminder-notification',
  templateUrl: './stop-reminder-notification.component.html',
  styles: [''],
})
export class StopRemindersComponent implements OnInit {
  sub: Subscription;

  constructor(
    public fb: FormBuilder,
    public alertService: AlertService,
    public deviceService: DeviceService,
    public route: ActivatedRoute,
    private router: Router,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    //call for snooze function
    this._stopReminder();
  }

  async _stopReminder() {
    //stop further reminders
    try {
      this.route.paramMap.subscribe(async (params) => {
        const notificationID = params.get('notification_id');
        const userID = params.get('user_id');
        this.alertService.getAlertsByNotificationAndUser(notificationID, userID).subscribe(async (alerts) => {
          if (alerts.docs.length > 0) {
            await this.alertService.stopReminderForUser(alerts.docs[0].id, userID);
            this.notify.update('Reminder has been stopped!!', 'success');
          } else this.notify.update('Reminder has already been stopped for this!!', 'warning');
          this.router.navigate(['/login']);
          //redirect login
        });
      });
    } catch (err) {
      console.log(err);
      this.notify.update('Something went wrong!', 'error');
      //redirect login
      this.router.navigate(['/login']);
    }
  }
}
