import { Injectable } from '@angular/core';
// import { DeviceService } from '../../core/device';
@Injectable()
export class DeviceDosageCalculationService {
  constructor() {}

  // Dosage Calculation start
  async getZoneDoges(device, deviceService) {
    return new Promise((resolve, reject) => {
      let serial = device.serial;

      if (serial != '') {
        let disconnectedAt = new Date().getTime();
        let connectedAt = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
        let CurrentHours = new Date().getHours();
        let connectedTime = new Date(connectedAt);
        let disconnectedTime = new Date(disconnectedAt);
        // let breating_calculated_zone = "0.00";
        deviceService.getConnectionData(serial, connectedTime, disconnectedTime).subscribe(async (data) => {
          let breathing_zone_dosage = device.breathing_zone_dosage !== undefined ? device.breathing_zone_dosage : 8;

          if (breathing_zone_dosage == 24) {
            let breating_calculated_zone = this.dogeCalculationWithMin(device, deviceService, 24);
            breating_calculated_zone.then((breatingCalculatedZoneData) => {
              resolve(breatingCalculatedZoneData);
            });
          } else if (breathing_zone_dosage == 8) {
            let breating_calculated_zone = this.dogeCalculationWithMin(device, deviceService, 8);
            breating_calculated_zone.then((breatingCalculated) => {
              resolve(breatingCalculated);
            });
          }
        });
      }
    });
  }

  /* 
  * Get Device's state log
  */
  async getStateData(device, deviceService) {
    return new Promise((resolve, reject) => {
      const serial = device.serial;
      const disconnectedAt = new Date().getTime();
      const connectedAt = new Date(new Date().setHours(0, 0, 0, 0)).getTime();
      const connectedTime = new Date(connectedAt);
      const disconnectedTime = new Date(disconnectedAt);

      deviceService.getModelLog(serial, connectedTime, disconnectedTime).subscribe(async (data) => {
        const result = data.filter((list) => {
          if (list !== null) {
            return list;
          }
        });
        resolve(result);
      });
    });
  }

  /**
   *
   * Get the first record of the day, from device state log, when the bulb is on.
   * So we can calculate for how log device was generating mjh 
   */
  async getFirstStateLogOfDay(device, deviceService, connectedTime, disconnectedTime) {
    let serial = device.serial;
    return new Promise((resolve, reject) => {
      deviceService.getModelLog(serial, connectedTime, disconnectedTime).subscribe(async (data) => {
        const result = data.filter((list) => {
          if (list !== null) {
            return list;
          }
        });
        resolve(result);
      });
    });
  }

  /**
   *  This function calculates, for how long device bulb remained on 
   *  This returns the result in minutes.
   */
  async dogeCalculationWithMin(device, deviceService, duration) {
    return new Promise((resolve, reject) => {
      const getStateData = this.getStateData(device, deviceService);
      if (duration == 24) {
        getStateData.then(async (breatingCalculatedZoneData: any) => {
          let minutes = breatingCalculatedZoneData.length;
          let totalDoagesData = await this.finalCalculation(deviceService, minutes, device);
          resolve(totalDoagesData);
        });
      } else if (duration == 8) {
        let dayFirstBulbOnData = null;
        getStateData.then((breatingCalculatedZoneData: any) => {
          if (breatingCalculatedZoneData.length > 0) {
            dayFirstBulbOnData = breatingCalculatedZoneData[0];
            let firstbulubOn = (dayFirstBulbOnData.at.seconds + 8 * 60 * 60) * 1000;
            //to check 8 hours
            let minightTime = new Date(new Date().setHours(23, 59, 0, 0)).getTime();

            let disconnectedAt = firstbulubOn;
            // To check if 8 hours time will be less then added 8 hours then mid night time will be considered as disconnected time
            if (minightTime < firstbulubOn) {
              disconnectedAt = minightTime;
            }

            let connectedTime = new Date(dayFirstBulbOnData.at.seconds * 1000);
            let disconnectedTime = new Date(disconnectedAt);

            const getState = this.getFirstStateLogOfDay(device, deviceService, connectedTime, disconnectedTime);
            getState.then(async (breatingCalculatedZone: any) => {
              let minutes = breatingCalculatedZone.length;
              let totalDoagesData = await this.finalCalculation(deviceService, minutes, device);
              resolve(totalDoagesData);
            });
          } else {
            resolve(0);
          }
        });
      }
    });
  }


  /**
   * convert cm to inch
  */
  async cmToInch(cm) {
    return cm / 2.54;
  }

  /**
   *
   * Calucation based on client provided sheet
   * Get the value of mj of given time span (e.g if device is on for 2 hours then it'll check how much mj has been generate in those 2 hrs)
   */
  async finalCalculation(deviceService, diffrence, device) {
    const name: any = await this.getModelData(deviceService, device);
    let ceiling_height = device.ceiling_height ? device.ceiling_height : 0;
    let breathing_zone = device.breathing_zone ? device.breathing_zone : 0;

    const mjhValues = {
      11: 0.14912,
      36: 0.8448,
    };

    let fluance = mjhValues[11];
    /* If name contains 36 or if name contains both 11 and 36 but 36 came before 11 in name. Then use flueance of 36 */
    if (name.indexOf(36) !== -1 && (name.indexOf(11) === -1 || (name.indexOf(11) !== -1 && name.indexOf(36) < name.indexOf(11)))) {
      fluance = mjhValues[36];
    }

    const calculatedData = await this.calculatemjPerSecond(ceiling_height, breathing_zone, fluance);
    const calculateDifferenceInMinutes = diffrence * 60;
    return calculateDifferenceInMinutes * calculatedData;
  }

  /* Calculate the value of mj, per second  */
  async calculatemjPerSecond(ceiling_height, breathing_zone_dosage, fluance) {
    if (ceiling_height - breathing_zone_dosage <= 0 || fluance < 0) {
      return 0;
    }
    return  (((12/2.54)**2)/((await this.cmToInch(ceiling_height-breathing_zone_dosage) )**2)*fluance);
  }

  /**
   * Get Model Data to compare mjhValues
   */
  async getModelData(deviceService, device) {
    return new Promise((resolve, reject) => {
      deviceService.getModelLogData(device.serial).subscribe(async (data) => {
        let model: string = '';
        if (data.length > 0) {
          model = data[0].model;
        }
        resolve(model);
      });
    });
  }
}
