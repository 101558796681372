import { Component, OnInit } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { AuthService } from '../../core/auth/auth.service'
import { NotifyService } from '../../core/notify/notify.service'

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styles: [`
    .authentication-wrapper {
      display: flex;
      flex-basis: 100%;
      min-height: 100vh;
      width: 100%;
    }
    .authentication-inner {
      width: 100%;
      max-width: 300px;
    }
    .authentication-1 {
      align-items: center;
      justify-content: center;
    }
  `]
})
export class ForgotComponent implements OnInit {

  form: FormGroup
  msg = null

  constructor(
    public fb: FormBuilder,
    public auth: AuthService,
    private notify: NotifyService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      'email': ['', [Validators.required]]
    })
  } 

  get email() { return this.form.get('email') }
  //send reset password email
  async confirm() {
    console.log('sending reset email for:', this.email.value)
    try { 
      if(await this.auth.resetPassword(this.email.value)) {
        this.notify.update("Reset Link has been sent.", 'success')
        this.msg = 'An email has been sent to your email address. Please follow the instructions to log back in.';
      } else {
        this.notify.update("Something went wrong!", 'warning')
        this.msg = 'An error occured. Please contact your Far UV support representative.'
      }
    } catch (err) {
      this.msg = 'An error occured. Please contact your Far UV support representative.'
    }
  }
}
