 import { AccessService } from './../access.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { userRoles } from '../../variables';

@Injectable({
  providedIn: 'root'
})
export class SystemAdminGuard implements CanActivate  {
  
  constructor(
    private router: Router,
    private authService: AuthService,
    private accessService : AccessService

  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
   
          const allowedRoles = route.data.allowedRoles;
          const roles = parseInt(JSON.parse(localStorage.getItem('user')).role) == userRoles.SysAdmin ? userRoles.SysAdmin:parseInt(localStorage.getItem('current-role'));

          if(allowedRoles && allowedRoles.indexOf(roles)=== -1){
 
            this.router.navigate(['/dashboard'])
            return false
          }

        
          return true
         
        }   
                    
}
  

