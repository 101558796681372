import {__core_private_testing_placeholder__} from '@angular/core/testing';
import { userRoles } from '../core/variables'

const Dashboard = {
  text: 'Dashboard',
  link: '/dashboard',
  icon: 'icon-speedometer',
  // role: 0
};

const OperatingManuals = {
  text: 'Operating Manuals',
  link: '/operating-manuals',
  icon: 'icon-notebook',
  // role: 0
};

const TermsOfService = {
  text: 'Terms Of Service',
  link: '/terms-of-service',
  icon: 'icon-doc',
  // role: 0
};

const Groups = {
  text: 'Groups',
  link: '/groups',
  icon: 'icon-globe',
  // role: 0
};

const Users = {
  text: 'Users',
  link: '/users',
  icon: 'icon-user',
  // role: 0
};

const OrgSettings = {
  text: 'Settings2',
  link: '/settings2',
  icon: 'icon-settings',
  role: 0,
};

const Settings = {
  text: 'Settings',
  link: '/settings',
  icon: 'icon-settings',
  // role: 3,
  submenu: [
    { 
      text: 'Default Schedules',
      link: '/settings/default-schedules'
    },
    {
      text: 'Schedules',
      link: '/settings/schedules',
      // icon: 'icon-clock',
    },
    {
      text: 'Notifications',
      link: '/settings/notifications',
      // icon: 'icon-bell',
    },
  ],
};

const Admin = {
  text: 'System Admin',
  link: '/sysadmin',
  icon: 'icon-user',
  // role: 3,
  submenu: [
    {
      text: 'Organizations',
      link: '/sysadmin/organizations',
    },
    {
      text: 'Devices',
      link: '/sysadmin/devices',
    },
    {
      text: 'Firmware',
      link: '/sysadmin/firmware',
    },
  ],
};

const headingMain = {
  text: 'Main Navigation',
  heading: true,
};

export const getDynamicMenu = () => {
  let menu = [];
  if (localStorage.getItem('user') == 'undefined') {
    menu = [];
  } else {
    const user = JSON.parse(localStorage.getItem('user'));

    if (localStorage.getItem('user') == 'null' || !localStorage.getItem('user')) {
      menu = [];
    }

    if (user != null && user != undefined && user.role != undefined) {
      const roles = JSON.parse(localStorage.getItem('user')).role == userRoles.SysAdmin ? userRoles.SysAdmin:parseInt(localStorage.getItem('current-role'));

      if (roles != undefined) {
      }

      if (roles == 3) {
        menu = [headingMain, Dashboard, OperatingManuals, TermsOfService, Groups,Settings, Admin];
      } else if (roles == 1) {
        menu = [headingMain, Dashboard, OperatingManuals, TermsOfService, Groups,Users, Settings];
      } else if (roles == 2) {
        menu = [headingMain, Dashboard, OperatingManuals, TermsOfService,Groups, Users, Settings];
      } else if (roles == 0) {
        menu = [headingMain, Dashboard, OperatingManuals, TermsOfService];
      }
    }
  }
  return menu;
};
export const menu = getDynamicMenu();
