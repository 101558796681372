import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router'

import { AuthService } from './auth.service'
import { TermsComplianceService } from '../terms-of-service'
import { Observable, from } from 'rxjs'
import { tap, map, take, switchMap } from 'rxjs/operators'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1), 
      map(user => !!user),
      tap(loggedIn => {
        if (!loggedIn) {
          console.log('access denied')
          this.router.navigate(['/login'])
        }
      })
    )
  }
}

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      map(user => !user),
      tap(notLoggedIn => {
        if (!notLoggedIn) {
          this.router.navigate(['/dashboard'])
        }
      })
    )
  }
}

@Injectable()
export class ComplianceGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private compliance: TermsComplianceService,
    private router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user.pipe(
      take(1),
      switchMap(user => from(this.compliance.isUserCompliant(user.uid))),
      tap(isCompliant => {
         if (!isCompliant) {
          console.log('Out of Compliance')
          this.router.navigate(['/terms-of-service'])
        }
      })
    )
  }
}
