import { Injectable } from '@angular/core'
import { AuthService } from '../auth/auth.service'

import { Observable, ReplaySubject } from 'rxjs'

@Injectable()
export class ProfileService {

  user$ = new ReplaySubject<any>()
  user = null
  roles = [
    'Operator',
    'Org Admin',
    'Org Manager',
    'SysAdmin'
  ]
  //set roles
  roleValues = {
    Operator: 0,
    Manager: 1,
    OrgOwner: 2,
    SysAdmin: 3
  }
  constructor(
    private auth: AuthService,
  ) {
    auth.user.subscribe(user => {
      if (user) {
        this.user = user
        this.user$.next(user)
      }
    })
  }
//set default props of loggedIn user
  get fullName() { return this.user ? `${this.user.firstName} ${this.user.lastName}` : '' }
  get title() { return this.user ? this.user.title : '' }
  get org() { return this.user ? this.user.orgId : '' }
  get uid() { return this.user.uid }
  get role() { return this.user.role }
  get roleName() { return this.roles[this.user.role] }

}
