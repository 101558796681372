import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable, of} from 'rxjs';
import {switchMap, map} from 'rxjs/operators';
import {DeviceState} from '../../models';
import {Device} from '../../models';

@Injectable()
export class DeviceStateService {
  collection: AngularFirestoreCollection<Device>;

  constructor(private afs: AngularFirestore) {
    this.collection = afs.collection<Device>('devices');
  }
  //fetch device current state
  getCurrentStateForDevice = (id: string): Observable<DeviceState> =>
    of(id).pipe( 
      switchMap((deviceId) =>
        this.afs
          .collection<DeviceState>(`devices/${deviceId}/stateLog`, (ref) => ref.orderBy('at', 'desc').limit(1))
          .valueChanges(),
      ),
      map((states) => {
        return {
          ...states[0],
          deviceId: id,
        };
      }),
    );
  //update device notification status
  updateDeviceNotificationStatus(deviceId) {
    return this.collection.doc(deviceId).update({is_notified: false});
  }

  //fetch last 8 hours rows
  lastEightHoursStateForDevice = (id: string): Observable<any> => {
    let last8hours = new Date().getTime() - 1000 * 60 * 60 * 8;
    return of(id).pipe(
      switchMap((deviceId) =>
        this.afs
          .collection<DeviceState>(`devices/${deviceId}/stateLog`, (ref) => ref.where('at', '>=', new Date(last8hours)).orderBy('at', 'desc'))
          .valueChanges(),
      ),
    );
  };
}
