import { Router, NavigationEnd } from '@angular/router';
import { UserService } from './core/user/user.service';
import { Component, HostBinding, OnInit } from '@angular/core';
declare var $: any;

import { SettingsService } from './core/settings/settings.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    loggedInUserDetails = JSON.parse(localStorage.getItem('user'))

    userRole
    mySubscription

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); };
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); };
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); };
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); };
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); };
    @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); };
    @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); };
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); };
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); };
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); };

    constructor(public settings: SettingsService,
                private userService : UserService,
                private router : Router
               ) {

                }

    ngOnInit() {
        $(document).on('click', '[href="#"]', e => e.preventDefault());

        if(this.loggedInUserDetails!=null && this.loggedInUserDetails!=undefined && this.loggedInUserDetails.role != undefined){

          this.userService.userInfoChanges(this.loggedInUserDetails.uid).subscribe(
            changes =>{

              this.userRole = this.loggedInUserDetails.role

              let currRole = parseInt(this.userRole)
              let changedRole = parseInt(changes.role)


              if(currRole !== changedRole){
                 window.location.reload();

              }
            }
          )

        }


    }


}
