import {firestore} from 'firebase';
import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Alert, User} from '../../models';
import {AuthService} from '../auth/auth.service';
import {NotifyService} from '../notify/notify.service';
import {ReplaySubject} from 'rxjs/ReplaySubject';
import {switchMap, map, filter} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class AlertService {
  orgId$ = new ReplaySubject<string>();
  activeAlerts$: Observable<Alert[]>;
  alerts: Alert[] = [];
  user: User;
  loaded = false;

  constructor(private afs: AngularFirestore, private auth: AuthService, private notify: NotifyService) {
    this.activeAlerts$ = this.orgId$.pipe(
      switchMap((orgId) =>
        afs
          .collection<Alert>('alerts', (ref) => ref.where('orgId', '==', orgId).where('dismissAlert', '==', false).orderBy('createdAt', 'desc'))
          .valueChanges(),
      ),
    );

    this.activeAlerts$.subscribe((alerts) => {
      this.alerts = [...alerts];
    });

    // defgault org
    auth.user.subscribe((user) => {
      this.user = user;
      if (user) {
        this.orgId$.next(user.orgId);
      }
    });
  }

  //update alerts
  updateOrgAlerts(orgId) {
    this.orgId$.next(orgId);
  }

  //fetch active alerts for organization
  getActiveAlertsByOrg(orgId): Observable<Alert[]> {
    this.updateOrgAlerts(orgId);
    return this.activeAlerts$;
  }

  //fetch active alerts for device
  getActiveAlertsByDevice(deviceId): Observable<Alert[]> {
    return this.activeAlerts$.pipe(map((alerts) => alerts.filter((alert) => alert.deviceId === deviceId)));
  }
  //fetch alert
  getActiveAlertsByAlertId(alertId): Observable<Alert[]> {
    return this.afs
      .collection<any>('alerts', (ref) => ref.where('id', '==', alertId))
      .valueChanges()
      .pipe(
        map((sItems) => {
          return sItems;
        }),
      );
  }
  //set snoze
  setSnoze(alertId, snozeDateTime: Date) {
    return this.afs.doc(`alerts/${alertId}`).update({
      snoze: snozeDateTime,
    });
  }
  //dismiss alert
  hideAlert(alertId, dismissReason: string) {
    return this.afs.doc(`alerts/${alertId}`).update({
      dismissReason: dismissReason,
      closedAt: new Date(),
      closedBy: this.user.uid,
      dismissAlert: true,
    });
  }
  //stop snoozing
  stopSnooze(notificationId) {
    return this.afs.doc(`notifications/${notificationId}`).update({
      stop_snooze: true,
      updated_at: new Date(),
    });
  }
  //close alert
  closeAlert(alertId) {
    return this.afs.doc(`alerts/${alertId}`).update({
      closedAt: new Date(),
      closedBy: this.user.uid,
      isActive: false,
    });
  }

  //fetch alert
  getAlertsByNotificationAndUser(notificationID, userID) {
    return this.afs
      .collection<any>('alerts', (ref) => ref.where('notificationId', '==', notificationID).where('users', 'array-contains', userID))
      .get()
      .pipe(
        map((sItems) => {
          return sItems;
        }),
      );
  }

  //close alert
  stopReminderForUser(alertId, userID) {
    return this.afs.doc(`alerts/${alertId}`).update({users: firestore.FieldValue.arrayRemove(userID)});
  }
}
