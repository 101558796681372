import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { firestore } from 'firebase'
import { BehaviorSubject } from 'rxjs'
import { map, tap } from 'rxjs/operators'

import { ProfileService } from '../auth/profile.service'
import { TermsOfService, TermsOfServiceCompliance } from '../../models'

@Injectable()
export class TermsService {

  private TOS_COL_NAME = 'terms_of_service'
  public activeTermsOfService = new BehaviorSubject<TermsOfService>(null)

  constructor(
    private afs: AngularFirestore,
    private profile: ProfileService,
  ) {
    this.afs.collection<TermsOfService>(
        this.TOS_COL_NAME,
        ref => ref.where('status', '==', 'active')
      )
      .valueChanges()
      .pipe(
        map(actives => actives[0]),
        tap(active => console.log(`New Terms Of Service Detected: ${active.id}`))
      )
      .subscribe(value => this.activeTermsOfService.next(value))
  }

  getActive(): TermsOfService {
    return this.activeTermsOfService.getValue()
  }

  async add(uploadPath: string): Promise<void> {
    const collection = firestore().collection(this.TOS_COL_NAME)
    const batch = firestore().batch()

    // retire previous terms of service document
    const previousTerms = await this.getActive()
    batch.update(collection.doc(previousTerms.id), {
      status: 'inactive'
    })

    // create new terms of service document
    const newTerms = collection.doc()
    batch.set(newTerms, <TermsOfService>{
      createdAt: new Date(),
      createdBy: this.profile.uid,
      id: newTerms.id,
      path: uploadPath,
      previousTermsOfServiceId: previousTerms.id,
      status: 'active'
    })

    return batch.commit()
  }

  delete(termsOfService: TermsOfService): Promise<void> {
    const collection = firestore().collection(this.TOS_COL_NAME)
    const batch = firestore().batch()

    // if this is active one then make previous terms active
    if (termsOfService.status === 'active') {
      batch.update(collection.doc(termsOfService.previousTermsOfServiceId), {
        status: 'active'
      })
    }

    // delete specified terms document
    batch.delete(collection.doc(termsOfService.id))

    return batch.commit()
  }
}

@Injectable()
export class TermsComplianceService {

  private TOS_COMPLIANCE_COL_NAME = 'terms_of_service_compliance'
  public currentComplianceDocument = new BehaviorSubject<TermsOfServiceCompliance>(null)

  constructor(
    private profile: ProfileService,
    private termsService: TermsService,
  ) { }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
  //fetch active terms
  async getComplianceDoc(userId: string): Promise<TermsOfServiceCompliance> {
    await this.delay(2000);
    const activeTerms = this.termsService.activeTermsOfService.getValue();
    return <TermsOfServiceCompliance>(await firestore()
      .doc(`${this.TOS_COMPLIANCE_COL_NAME}/${userId}_${activeTerms.id}`)
      .get()).data()
  }

  async isUserCompliant(userId: string): Promise<boolean> {
    const complianceDoc = await this.getComplianceDoc(userId)
    return !!complianceDoc && complianceDoc.isAcknowledged
  }

  addCompliance(userId: string, termsOfServiceId: string): Promise<void> {
    const collection = firestore().collection(this.TOS_COMPLIANCE_COL_NAME)
    const id = `${userId}_${termsOfServiceId}`

    // create new terms of service compliance document
    const newCompliance = collection.doc(id)
    return newCompliance.set(<TermsOfServiceCompliance>{
      createdAt: new Date(),
      createdBy: this.profile.uid,
      id,
      isAcknowledged: true,
      termsOfServiceId,
      userId
    })
  }
}
