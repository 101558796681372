import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ProfileService } from '../../../core/auth'

@Component({
    selector: 'app-admin-existing-user-add',
    templateUrl: './existing-user-add.component.html',
})
export class ExistingUserAddComponent implements OnInit {

  form: FormGroup
  @Input() isUpdating: boolean
  @Input() allUser: any
  @Input() listRoles: any
  @Output() data = new EventEmitter<object>()
  @Output() cancel = new EventEmitter<object>()

  constructor(
    public fb: FormBuilder,
    public profileService: ProfileService,
  ) { }

  ngOnInit() {
    //set form
    this.form = this.fb.group({
      'user_id': ['', [Validators.required]],
      'role': ['', [Validators.required]],
    })
  }

  get user_id() { return this.form.get('user_id') }
  get role() { return this.form.get('role') }

  submit() {
    this.data.emit({ ...this.form.value })
    this.form.reset()
  }

  close(event) {
    this.cancel.emit({ ...event })
  }
}
