import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { Observable, ReplaySubject, of, combineLatest } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { Device, DeviceState } from '../../models'

@Injectable()
export class OrganizationDeviceStateService {

  private deviceStates$: Observable<DeviceState[]>
  private devices$ = new ReplaySubject<Device[]>()

  constructor (private afs: AngularFirestore) {
    this.deviceStates$ = this.devices$.pipe(
      switchMap(devices => combineLatest(devices.map(device => this.getCurrentStateForDevice(device))))
    )
  }

  private getCurrentStateForDevice = (device: Device): Observable<DeviceState> => of(device).pipe(
    switchMap(unit => this.afs
      .collection<DeviceState>(`devices/${unit.serial}/stateLog`,
        ref => ref.orderBy('at', 'desc').limit(1)
      ).valueChanges()),
    map(states => {
      return {
        ...states[0],
        deviceId: device.serial
      }
    })
  )

  setDevices(devices): void {
    this.devices$.next(devices)
  }

  getOnlineDeviceStates(): Observable<DeviceState[]> {
    return this.deviceStates$
  }
}
