import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/firestore'
import { firestore, storage } from 'firebase'
import { Observable } from 'rxjs'

import { ProfileService } from '../auth/profile.service'
import { OperatingManual } from '../../models'

@Injectable()
export class ManualsService {

  private TOS_COL_NAME = 'operatingManuals'

  constructor(
    private afs: AngularFirestore,
    private profile: ProfileService,
  ) { }

  getActives(): Observable<OperatingManual[]> {
    return this.afs.collection<OperatingManual>(
        this.TOS_COL_NAME,
        ref => ref.where('status', '==', 'active')
      )
      .valueChanges()
  }

  async add(data: {uploadPath: string, name: string, description: string}): Promise<void> {
    const collection = firestore().collection(this.TOS_COL_NAME)
    const batch = firestore().batch()

    // create new manual document
    const newManual = collection.doc()
    batch.set(newManual, <OperatingManual>{
      createdAt: new Date(),
      createdBy: this.profile.uid,
      description: data.description,
      id: newManual.id,
      name: data.name,
      path: data.uploadPath,
      status: 'active'
    })

    return batch.commit()
  }

  async delete(manual: OperatingManual): Promise<void> {
    try {
      // delete the file
      await storage().ref(manual.path).delete()

      // on success, delete the associated record
      const collection = firestore().collection(this.TOS_COL_NAME)
      const batch = firestore().batch()
      batch.delete(collection.doc(manual.id))
      await batch.commit()
    } catch (err) {
      console.error(err)
    }
  }
}
