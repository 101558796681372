import { RouterModule } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor() { }

  authorizedRoute(allowedRoles : any) : boolean{

    if(allowedRoles == null){
      return true;
    }
    
    const roles = JSON.parse(localStorage.getItem('user')).role
    return !allowedRoles.includes(roles)
  }
}
